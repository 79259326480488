@use 'node_modules/@pathos/pathoslogos-ui/assets/styles/tokens.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
html {
  height: 100%;
  font-size: 16px;
}

body {
  height: 100%;
}

.box-main {
  border-radius: tokens.$shape-l;
  background-color: tokens.$surface-default-emphasis-base;
}

.box-spacing-600 {
  @extend .box-main;
  padding: tokens.$spacing-600;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: tokens.$shape-xs;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drop-list-dragging {
  .cdk-drag:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}
